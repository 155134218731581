import { Component, ErrorInfo, ReactNode } from 'react';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";

interface Props {
  children: ReactNode;
  fallback?: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
    errorInfo: null
  };

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error, errorInfo: null };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
    this.setState({
      error,
      errorInfo
    });

    // Send detailed error information to our logging service
    const errorDetails = {
      error: error.toString(),
      componentStack: errorInfo.componentStack,
      timestamp: new Date().toISOString(),
      location: window.location.href,
      userAgent: navigator.userAgent,
      // Add additional context that might be helpful
      reactVersion: process.env.NODE_ENV,
      // Sanitize the stack trace
      sanitizedStack: errorInfo.componentStack?.replace(/\?authToken=([^&]+)/g, '?authToken=REDACTED'),
    };
    
    // Log error to server with retry logic
    const sendErrorLog = async (retries = 3): Promise<void> => {
      try {
        const response = await fetch('/api/log-error', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(errorDetails),
        });
        
        if (!response.ok) {
          throw new Error(`Failed to log error: ${response.statusText}`);
        }
      } catch (error) {
        console.error('Failed to send error log:', error);
        if (retries > 0) {
          // Exponential backoff
          await new Promise(resolve => setTimeout(resolve, Math.pow(2, 4 - retries) * 1000));
          return sendErrorLog(retries - 1);
        }
      }
    };
    
    // Start logging process
    sendErrorLog().catch(console.error);
  }

  private handleReset = () => {
    this.setState({
      hasError: false,
      error: null,
      errorInfo: null
    });
  };

  public render() {
    if (this.state.hasError) {
      // Custom fallback UI
      return this.props.fallback || (
        <Card className="w-full max-w-md mx-auto mt-8 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
          <CardHeader>
            <CardTitle className="text-destructive">Something went wrong</CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            <p className="text-sm text-muted-foreground">
              We apologize for the inconvenience. An error has occurred in this part of the application.
            </p>
            {process.env.NODE_ENV === 'development' && this.state.error && (
              <div className="p-4 rounded-md bg-destructive/10 text-destructive text-sm font-mono overflow-auto">
                <p>{this.state.error.toString()}</p>
                {this.state.errorInfo && (
                  <pre className="mt-2 text-xs">{this.state.errorInfo.componentStack}</pre>
                )}
              </div>
            )}
          </CardContent>
          <CardFooter>
            <Button onClick={this.handleReset}>Try Again</Button>
          </CardFooter>
        </Card>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
