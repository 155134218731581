import { z } from 'zod';

// User tier definitions
export const UserTierValues = {
  visitor: 'visitor',
  freemium: 'freemium',
  premium: 'premium',
  ultimate: 'ultimate'
} as const;

export type UserTier = typeof UserTierValues[keyof typeof UserTierValues];

// Template tier definitions
export const TemplateTierValues = {
  freemium: 'freemium',
  premium: 'premium',
  ultimate: 'ultimate'
} as const;

export type TemplateTier = typeof TemplateTierValues[keyof typeof TemplateTierValues];

// Tier level definitions for access control
export const tierLevels = {
  visitor: -1,
  freemium: 1,
  premium: 2,
  ultimate: 3
} as const;

// Type-safe tier access mapping
export const userTierToTemplateAccess: Record<UserTier, TemplateTier> = {
  visitor: 'freemium',
  freemium: 'freemium',
  premium: 'premium',
  ultimate: 'ultimate'
};

// User session interface
export interface UserSession {
  uid: string;
  email: string | null;
  isAdmin: boolean;
  tier: UserTier;
  requiredTier?: TemplateTier;
}

// Re-export for module augmentation
export type { UserSession as ExpressUserSession };

// Template content type
export interface TemplateContent {
  id: number;
  type: 'text' | 'code' | 'link' | 'file';
  content: string;
}

export const templateContentSchema = z.object({
  id: z.number(),
  type: z.enum(['text', 'code', 'link', 'file']),
  content: z.string()
});

export type TemplateContentType = z.infer<typeof templateContentSchema>;

// Utility types for tier-based features
export interface TierFeatures {
  canCreate: boolean;
  canEdit: boolean;
  canShare: boolean;
  canDownload: boolean;
  maxTemplates: number;
}

// User role interface
export interface UserRole {
  isAdmin: boolean;
  tier: UserTier;
}

// Tier features configuration
export const tierFeatures: Record<TemplateTier, TierFeatures> = {
  freemium: {
    canCreate: false,
    canEdit: false,
    canShare: true,
    canDownload: true,
    maxTemplates: 5
  },
  premium: {
    canCreate: false,
    canEdit: false,
    canShare: true,
    canDownload: true,
    maxTemplates: 20
  },
  ultimate: {
    canCreate: true,
    canEdit: true,
    canShare: true,
    canDownload: true,
    maxTemplates: Infinity
  }
};

// Utility functions for tier checks
export function canAccessTier(userTier: UserTier, requiredTier: TemplateTier): boolean {
  return tierLevels[userTier] >= tierLevels[requiredTier];
}

export function getFeaturesByTier(tier: TemplateTier): TierFeatures {
  return tierFeatures[tier];
}

// Template interface
export interface Template {
  id: number;
  title: string;
  description?: string | null;
  content: TemplateContent[];
  thumbnail?: string | null;
  tier: TemplateTier;
  requiredTier: TemplateTier;
  isLocked: boolean;
  views: number;
  shares: number;
  categoryId?: number | null;
  createdAt: Date;
  updatedAt: Date;
  readme?: string | null;
}

// Global type augmentations
declare global {
  namespace Express {
    interface User {
      email: string | null;
      uid: string;
      isAdmin: boolean;
      tier: UserTier;
    }
  }
}
