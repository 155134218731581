import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID
};

// Initialize Firebase
console.log('Initializing Firebase...');
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Set persistence to LOCAL
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log('Firebase persistence set to local');
  })
  .catch((error) => {
    console.error('Error setting persistence:', error);
  });

// Listen for auth state changes
auth.onAuthStateChanged(async (user) => {
  if (user) {
    console.log('Auth state changed: User', user.email, 'signed in');
    try {
      // Get the ID token
      const idToken = await user.getIdToken();
      
      // Verify token with backend
      const response = await fetch('/api/auth/verify-token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idToken })
      });

      if (!response.ok) {
        throw new Error('Failed to verify token with backend');
      }

      // Store token in localStorage for API requests
      localStorage.setItem('authToken', idToken);
      
      console.log('Firebase client initialized successfully');
    } catch (error) {
      console.error('Error during auth initialization:', error);
    }
  } else {
    console.log('Auth state changed: User signed out');
    localStorage.removeItem('authToken');
  }
});

// Add interceptor for API requests
const originalFetch = window.fetch;
window.fetch = async function(input: RequestInfo | URL, init?: RequestInit) {
  const token = localStorage.getItem('authToken');
  if (token && (typeof input === 'string' && input.startsWith('/api'))) {
    init = init || {};
    init.headers = {
      ...init.headers,
      'Authorization': `Bearer ${token}`
    };
  }
  return originalFetch(input, init);
};

export { app, auth };
