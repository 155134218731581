import { Link } from "wouter";
import { Linkedin, Youtube, Mail, Globe } from "lucide-react";

export default function Footer() {
  return (
    <footer className="relative mt-auto bg-background/50 backdrop-blur-lg border-t border-white/10">
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Company Info */}
          <div className="space-y-4">
            <img 
              src="/uploads/logo.png" 
              alt="AI Playgrounds"
              width={32}
              height={32}
              className="h-8 w-auto object-contain"
              onError={(e) => {
                console.error('Logo failed to load:', e);
                e.currentTarget.style.display = 'none';
              }}
            />
            <p className="text-sm text-muted-foreground">
              AI PLAYGROUNDS PTY LTD<br />
              ABN: 63 682 155 399
            </p>
          </div>

          {/* Contact */}
          <div>
            <h3 className="font-semibold mb-4">Contact</h3>
            <div className="space-y-3">
              <a 
                href="mailto:info@aiplaygrounds.xyz"
                className="flex items-center gap-2 text-sm text-muted-foreground hover:text-primary transition-colors"
              >
                <Mail className="w-4 h-4" />
                info@aiplaygrounds.xyz
              </a>
              <a 
                href="https://aiplaygrounds.xyz"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 text-sm text-muted-foreground hover:text-primary transition-colors"
              >
                <Globe className="w-4 h-4" />
                aiplaygrounds.xyz
              </a>
            </div>
          </div>

          {/* Social */}
          <div>
            <h3 className="font-semibold mb-4">Follow Us</h3>
            <div className="space-y-3">
              <a 
                href="https://www.linkedin.com/in/valentino-margarit-865b76290/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 text-sm text-muted-foreground hover:text-primary transition-colors"
              >
                <Linkedin className="w-4 h-4" />
                LinkedIn
              </a>
              <a 
                href="https://www.youtube.com/@aiplaygrounds"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 text-sm text-muted-foreground hover:text-primary transition-colors"
              >
                <Youtube className="w-4 h-4" />
                YouTube
              </a>
            </div>
          </div>

          {/* Credits */}
          <div>
            <h3 className="font-semibold mb-4">Created By</h3>
            <p className="text-sm text-muted-foreground">
              Created and powered by Valentino<br />
              <a 
                href="https://aiplaygrounds.xyz"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:text-primary/80 transition-colors"
              >
                aiplaygrounds.xyz
              </a>
            </p>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-12 pt-8 border-t border-white/10">
          <p className="text-sm text-center text-muted-foreground">
            © 2024 AI Playgrounds. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
} 