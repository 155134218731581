import { User as FirebaseUser } from "firebase/auth";
import { 
  UserTier, 
  TemplateTier, 
  UserRole, 
  UserTierValues, 
  tierLevels,
  getFeaturesByTier,
  canAccessTier 
} from "@/types/shared";

export { getFeaturesByTier, canAccessTier };

export interface AuthUser extends Omit<FirebaseUser, 'providerData'> {
  isAdmin: boolean;
  tier: UserTier;
}

export interface AuthError {
  code: string;
  message: string;
}

export const ADMIN_EMAIL = 'agencympire@gmail.com';

export function getUserRole(email: string | null): UserRole {
  if (!email) {
    return { isAdmin: false, tier: UserTierValues.visitor };
  }

  if (email === ADMIN_EMAIL) {
    return { isAdmin: true, tier: UserTierValues.ultimate };
  }

  // All logged-in users get freemium access by default
  return { isAdmin: false, tier: UserTierValues.freemium };
}

export function canAccessTemplate(userTier: UserTier, templateTier: TemplateTier): boolean {
  return tierLevels[userTier] >= tierLevels[templateTier];
}

export function canPerformAction(
  action: 'view' | 'copy' | 'download' | 'share' | 'edit' | 'delete' | 'create',
  userRole: UserRole,
  templateTier?: TemplateTier
): boolean {
  // Only ultimate tier admins can perform certain actions
  if (['edit', 'delete', 'create'].includes(action)) {
    return userRole.isAdmin && userRole.tier === UserTierValues.ultimate;
  }

  // For other actions
  switch (action) {
    case 'view':
      return templateTier ? canAccessTemplate(userRole.tier, templateTier) : true;
    case 'copy':
    case 'download':
    case 'share':
      return userRole.tier !== 'visitor' && (!templateTier || canAccessTemplate(userRole.tier, templateTier));
    default:
      return false;
  }
}

export function getTierBadgeColor(tier: TemplateTier | UserTier): string {
  switch (tier) {
    case UserTierValues.ultimate:
      return 'bg-gradient-to-r from-yellow-600 to-purple-600 text-white';
    case UserTierValues.premium:
      return 'bg-gradient-to-r from-purple-600 to-pink-600 text-white';
    case UserTierValues.freemium:
      return 'bg-gradient-to-r from-blue-600 to-cyan-600 text-white';
    case UserTierValues.visitor:
    default:
      return 'bg-gradient-to-r from-gray-600 to-gray-700 text-white';
  }
}

export function getTierLabel(tier: TemplateTier | UserTier): string {
  switch (tier) {
    case UserTierValues.ultimate:
      return '⭐ Ultimate';
    case UserTierValues.premium:
      return '🌟 Premium';
    case UserTierValues.freemium:
      return '✨ Freemium';
    case UserTierValues.visitor:
      return '👋 Visitor';
    default:
      return tier;
  }
}
